<template>
  <router-view />
</template>

<script>

export default {
  name: 'MiscellaneousWrapper'
}
</script>
